<template>
    <b-card no-body>
        <div class="row px-1 pt-1 align-items-end">
            <div class="col-md-2 mb-1">
                <label class="form-label">Tarih Aralığı</label>
                <v-date-picker v-model="date" range placeholder="Tarih Aralığı"></v-date-picker>        
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Bitiş Firma</label>
                <v-select-list v-model="targetCari" :options="targetCariList" label="CARI_ISIM" v-on:search="getTargetCariList" placeholder="Bitiş Firma" :loading="targetCariLoading"></v-select-list>
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Stok</label>
                <v-select-list v-model="stok" :options="stoks" label="Name"  placeholder="Stok" :loading="stokLoading"></v-select-list>
            </div>
            <div class="col-auto mb-1">
                <v-executer :loading="filterLoading" :action="filter">Filtrele</v-executer>
            </div>
            <div class="col-auto mb-1">
                <v-executer :loading="exporting" variant="success" :action="downloadExcel">Excel</v-executer>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered bordered-header align-middle">
                <thead>
                    <tr>
                        <th rowspan="2" class="align-middle w-15 text-nowrap">Grup Kodu</th>
                        <th rowspan="2" class="align-middle w-15 text-nowrap">Grup Adı</th>
                        <th rowspan="2" class="align-middle w-15 text-nowrap">Stok Kodu</th>
                        <th rowspan="2" class="align-middle w-15 text-nowrap">Stok Adı</th>
                        <th rowspan="2" class="align-middle w-55 text-nowrap">Cari</th>
                        <th colspan="2" class="text-center text-nowrap">Çelemli</th>
                        <th colspan="2" class="text-center text-nowrap">Narlı</th>
                        <th colspan="2" class="text-center text-nowrap">Çukurova</th>
                        <th rowspan="2" class="align-middle w-15 text-nowrap">Toplam Adet</th>
                        <th rowspan="2" class="align-middle w-15 text-nowrap">Toplam Tonaj</th>
                    </tr>
                    <tr>
                        <th class="text-nowrap text-right">Toplam Taşıma</th>
                        <th class="text-nowrap text-right">Toplam Tonaj</th>
                        <th class="text-nowrap text-right">Toplam Taşıma</th>
                        <th class="text-nowrap text-right">Toplam Tonaj</th>
                        <th class="text-nowrap text-right ">Toplam Taşıma</th>
                        <th class="text-nowrap text-right ">Toplam Tonaj</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(d,i) in data">
                        <tr v-if="!d.Stats">
                            <td v-if="d.GroupIsFirst" :rowspan="d.GroupRowSpan" class="text-nowrap">{{ d.GroupCode }}</td>
                            <td v-if="d.GroupIsFirst" :rowspan="d.GroupRowSpan" class="text-nowrap">{{ d.GroupName }}</td>
                            <td v-if="d.StokIsFirst" :rowspan="d.StokRowSpan" class="text-nowrap">{{ d.StokKodu }}</td>
                            <td v-if="d.StokIsFirst" :rowspan="d.StokRowSpan" class="text-nowrap">{{ d.StokAdi }}</td>
                            <td class="text-nowrap">{{ d.Cari }}</td>
                            <td class="text-right">{{ d.Celemli.TasimaSayisi }}</td>
                            <td class="text-right">{{ d.Celemli.TasimaToplamTonaj }}</td>
                            <td class="text-right">{{ d.Narli.TasimaSayisi }}</td>
                            <td class="text-right">{{ d.Narli.TasimaToplamTonaj }}</td>
                            <td class="text-right">{{ d.Cukurova.TasimaSayisi }}</td>
                            <td class="text-right">{{ d.Cukurova.TasimaToplamTonaj }}</td>
                            <td class="text-right">{{ d.TasimaSayisi }}</td>
                            <td class="text-right">{{ d.TasimaToplamTonaj }}</td>
                        </tr>
                        <tr v-if="d.Stats" class="bg-light-secondary">
                            <td class="text-right text-secondary font-weight-bolder">TOPLAM</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.CelemliTotalCount, }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.CelemliTotalTonnage, }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.NarliTotalCount, }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.NarliTotalTonnage, }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.CukurovaTotalCount, }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.CukurovaTotalTonnage, }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.TotalCountTasimaSayisi }}</td>
                            <td class="text-right text-secondary font-weight-bolder">{{ d.TotalTonnage }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { NetsisApi } from '@/api/netsis'
import { MaterialApi } from '@/api/material'
import moment from 'moment'
import Additional from '@/utilities/additional'

export default {
    components:{
        BCard
    },
    data(){
        return {
            queryData:{
                StartDate: moment().startOf('month').format('YYYY-MM-DD'),
                EndDate:moment().endOf('month').format("YYYY-MM-DD"),
                CariCode:null,
                TargetCariCode:null,
                StockCode:null,
                BranchCode:null,
                RegionCode:null
            },
            targetCariList:[],
            targetCariLoading:false,
            stoks:[],
            stokLoading:false,
            filterLoading:false,
            sefers:[],
            exporting:false,
        }
    },
    mounted(){
        this.getStoks()
    },
    methods:{
        getTargetCariList(searchKey){
            if(searchKey){
                this.targetCariLoading = true
                NetsisApi.CariList(searchKey).then(res => {
                    this.targetCariList = res.data.Data
                    this.targetCariLoading = false
                }).catch(err=> {
                    console.log(err)
                    this.targetCariLoading = false
                })
            }
        },
        getStoks(){
            this.stokLoading = true
            MaterialApi.List().then(res => {
                this.stoks = res.data.Data
                this.stokLoading = false
            }).catch(err=> {
                console.log(err)
                this.stokLoading = false
            })
        },
        filter(){
            this.filterLoading = true
            NetsisApi.GetSeferListReport(this.queryData).then(res => {
                if(!res.data.Success){
                    this.$result(res)
                }else{
                    this.sefers = res.data.Data
                }
                this.filterLoading = false
            }).catch(err=> {
                console.log(err)
                this.filterLoading = false
            })
        },
        downloadExcel(){
            this.exporting = true
            NetsisApi.GetSeferListReportExcel(this.queryData.StartDate,this.queryData.EndDate).then(res=>{
                this.exporting = false
                Additional.DownloadFile(res.data, `Sefer Raporu - ${this.queryData.StartDate} - ${this.queryData.EndDate}.xlsx`)
            }).catch(err => {
                this.exporting = false
            })
        },
        getMaxCariCount(s){
            return s.SubItems.map(r => r.ReportItem.map(rr => rr.ReportItem.length )).reduce((s,i) => s += Number(i),0)
        },
        getStokMaxCariCount(st){
            return Math.max(...st.ReportItem.map(r => r.ReportItem.length),1)
        }
    },
    computed:{
        data(){
            const getStats = (key,stats = {}) => {
                const k = Object.keys(stats).find(kk => Additional.ToEng(kk).toLowerCase().includes(Additional.ToEng(key).toLowerCase()))
                return stats[k] || {TasimaToplamTonaj:0,TasimaSayisi:0}
            }

            return this.sefers.reduce((arr,s,_i) => {
                s.SubItems.forEach((si,_sii) => {
                    si.ReportItem.forEach((ri,_rii) => {
                        ri.ReportItem.forEach((rii,_riii) => {
                            arr.push({
                                GroupIsFirst: _rii == 0 && _riii == 0 && _sii == 0,
                                GroupRowSpan: s.SubItems.map(r => r.ReportItem.map(rr => rr.ReportItem.length )).reduce((s,i) => s += Number(i),0) + s.SubItems.length,
                                GroupCode:s.GroupCode,
                                GroupName:s.GroupName,
                                StokIsFirst:_rii == 0 && _riii == 0,
                                StokRowSpan: si.ReportItem.map(rr => rr.ReportItem.length).reduce((s,i) => s += Number(i),0) + 1,
                                StokKodu:si.StokKodu,
                                StokAdi:si.StokAdi,
                                Cari:rii.CariIsim,
                                Celemli:getStats('Çelemli',rii.BySubeStats),
                                Narli:getStats('Narlı',rii.BySubeStats),
                                Cukurova:getStats('Çukurova',rii.BySubeStats),
                                TasimaSayisi:rii.TasimaSayisi,
                                TasimaToplamTonaj:rii.TasimaToplamTonaj
                            })
                        })
                        arr.push({
                            Stats:true,
                            TotalCountTasimaSayisi: ri.TotalCountTasimaSayisi,
                            TotalTonnage:ri.TotalTonnage,
                            CelemliTotalCount:ri.CelemliTotalCount,
                            CelemliTotalTonnage:ri.CelemliTotalTonnage,
                            CukurovaTotalCount:ri.CukurovaTotalCount,
                            CukurovaTotalTonnage:ri.CukurovaTotalTonnage,
                            NarliTotalCount:ri.NarliTotalCount,
                            NarliTotalTonnage:ri.NarliTotalTonnage,
                        })
                    })
                })
                return arr
            },[])
        },
        date:{
            get:function(){
                return [this.queryData.StartDate,this.queryData.EndDate].filter(d=>d != null)
            },
            set:function(val){
                this.queryData.StartDate = val[0] || null
                this.queryData.EndDate = val[1] || null
            }
        },
        targetCari:{
            get:function(){
                return this.targetCariList.find(s=>s.CARI_KOD==this.queryData.TargetCariCode)
            },
            set:function(value){
                this.queryData.TargetCariCode = value?.CARI_KOD || null
            }
        },
        stok:{
            get:function(){
                return this.stoks.find(s=>s.Id==this.queryData.Id)
            },
            set:function(value){
                this.queryData.StockCode = value?.Code || null
            }
        },
    }
}
</script>